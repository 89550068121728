<template>
  <div class="controls-modal">
    <div class="controls-modal__title">
      {{ product.name }}
    </div>
    <SimpleButton
      :color="'red'"
      :font="'minor'"
      :additionalClass="'cog'"
      @clickedFromSimpleBtn="selectProduct"
    >
      <template #icon>
        <img src="@/assets/img/icons/cog--white.svg" alt="" class="icon" />
      </template>
      {{ $t(`message.buttons.customize.${placement}`) }}
    </SimpleButton>
    <div class="controls-modal__footer" v-if="product.text !== '' || product.url">
      <div class="text" v-if="product.text !== ''">{{ product.text }}</div>
      <a
        :href="product.url"
        target="_blank"
        class="link"
        v-if="product.url && product.url !== ''"
        :class="{ mt: product.text !== '' }"
      >
        <img src="@/assets/img/icons/planet--red.svg" alt="" />
        {{ $t('message.systems.more') }}
      </a>
    </div>
  </div>
</template>

<script>
import SimpleButton from '@/components/elements/Dom/Simple-button'
export default {
  props: {
    product: Object,
    placement: {
      type: String,
      default: 'system',
      required: true
    }
  },
  components: {
    SimpleButton
  },
  methods: {
    selectProduct() {
      this.$emit('selectFromMobileModal', this.product)
    }
  }
}
</script>

<style scoped lang="sass">
.controls-modal
  max-width: 92vw
  width: rem(368)
  padding: rem(24) rem(24) rem(30)
  box-sizing: border-box
  &__title
    @extend .fs-14
    font-weight: bold
    padding-bottom: rem(24)
    color: $black
  .simple-btn
    padding: 0 rem(16)
    .icon
      margin-right: rem(16)
  &__footer
    margin-top: rem(24)
    padding-top: rem(24)
    box-shadow: inset 0 rem(1) 0 #ececec
    .text
      @extend .fs-12
      color: $light-black
    .link
      @extend .fs-12
      color: $red
      font-weight: bold
      display: flex
      flex-direction: row
      align-items: center
      img
        margin-right: rem(12)
      &.mt
        margin-top: rem(25)
</style>
